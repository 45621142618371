<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-30 center">
      <md-card>
        <md-card-header>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-small-size-30 md-small-size-100">
            <div class="md-layout-item">
              <md-menu md-size="big" md-direction="bottom-end">
                <md-button class="md-icon-button" @click="go_to('/fiscal_year_list')">
                  <md-icon>keyboard_backspace</md-icon>
                </md-button>
              </md-menu>
              <md-field :class="getValidationClass('year')">
                <label for="type_document">Seleccionar Año</label>
                <md-select v-model="instance.year"
                    name="fiscal_year"
                    id="fiscal_y"
                    :disabled="sending"
                  >
                  <md-option :value="item.id"
                    v-for="(item, key) in years"
                    :key="key">{{item.year}}
                  </md-option>
                  </md-select>
                <span class="md-error"
                  v-if="!$v.instance.year.required">EL año es requerido</span>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-checkbox v-model="seleccionar_despues"
              >¿Seleccionar año luego de crearlo?</md-checkbox>
                <span v-if="seleccionar_despues" style="color: #ff5252;
                  font-weight: 500;" for="services">SÍ</span>
                <span v-if="!seleccionar_despues" style="color: #ff5252;
                    font-weight: 500;" for="services">NO</span>
            </div>
          </div>
          <div class="centerButton">
              <md-button type="submit" class="md-primary" @click="save()"
                :disabled="sending">Crear año fiscal</md-button>
          </div>
        </md-card-content>

      </md-card>
    </div>
    <md-snackbar
      :md-active.sync="saved">Año fiscal {{ document }} ha sido actualizado!</md-snackbar>

    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
  </div>
</template>
<script>
/* eslint-disable */
import { validationMixin } from 'vuelidate'
import {
  required,
} from 'vuelidate/lib/validators'
export default {
  name: 'Fiscal_year',
  created(){
    let year_selected_storage =this.get_data_storage_raw('id_fiscal_year')
    if (!year_selected_storage) {
      this.seleccionar_despues = true
    }
    this.list()
  },
  mixins: [validationMixin],
  components: {
    upload: () => import('../components/Upload.vue'),
  },
  data() {
    return {
      instance: {
        year: null
      },
      years: [],
      seleccionar_despues: false,
      sending: false,
      saved: false,
      document: null,
      url: 'fiscal_year/',
      sending_request: false,
    };
  },
  validations: {
    instance: {
      year: {
        required,
      },
    },
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    list() {
      const self = this;
      let today = new Date();
      let year = today.getFullYear();
      self.years.push({ id: year, year: year});
      self.years.push({ id: parseInt(year)+2, year: parseInt(year)+2});
      self.years.push({ id: parseInt(year)+1, year: parseInt(year)+1});
      self.years.push({ id: parseInt(year)-1, year: parseInt(year)-1});
      self.years.push({ id: parseInt(year)-2, year: parseInt(year)-2});
      self.years.push({ id: parseInt(year)-3, year: parseInt(year)-3});
      self.years.push({ id: parseInt(year)-4, year: parseInt(year)-4});
      self.years.push({ id: parseInt(year)-5, year: parseInt(year)-5});
      self.years.push({ id: parseInt(year)-6, year: parseInt(year)-6});
    },
    save() {
      const payload = this.instance
      const self = this;
      if (!this.instance.year) {
        self.$swal.fire('Error!', 'Seleccione año', 'error')
        return false
      }else{
        payload.company = localStorage.getItem('office')
        this.postRequest(payload).then(function (data) {

        self.document = `${self.instance.year}`
        self.saved = true
        self.sending = false
        if (self.seleccionar_despues) {
          self.save_storage_raw('id_fiscal_year', data.data.id)
          self.seleccionar_despues = false
        }

        setTimeout(() => {
          self.$router.push('fiscal_year_list')
        }, 500);
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
      }
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url,{params:  payload});
      // const result = await this.$http.get(this.url, payload);
      return result;
    },
    async postRequest (payload) {
      const result = await this.$http.post(this.url, payload)
      return result
    },
    async sendRequest_with_url(url, payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    save_storage_raw(key, data) {
      localStorage.setItem([key], data);
    },
    get_data_storage_raw(name) {
      return localStorage.getItem(name);
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
};

</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
  .avatar img {
    max-width: 30px;
  }
</style>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .center{
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .centerButton{
    text-align: center;
  }
  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
